import React from 'react'
import banner from '../../assets/images/banner.jpg';
import previcon from '../../assets/images/prev-black.svg';
import nexticon from '../../assets/images/next-black.svg'
import Slider from "react-slick";

const Homesection2 = (props) => {
  const {setaccredeiation1} = props; 

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: (
      <img src={nexticon} />
    ), 
    nextArrow:(
      <img src={previcon} />
    ),
    responsive: [
      {
          breakpoint: 1024,
          settings: {
              slidesToShow: 6
          }
      },
      {
          breakpoint: 992,
          settings: {
              slidesToShow: 3
          }
      },
      {
          breakpoint: 450,
          settings: {
              slidesToShow: 2
          }
      },
      {
          breakpoint: 320,
          settings: {
              slidesToShow: 1
          }
      }
  ]
  };
  
  return (
    <div className='pt-4 pb-4'>
      <div className='container'>
        <div className='customsliderpadding homesection2slider'>
        <Slider className='logo-slider' {...settings}>
        {
                setaccredeiation1 && setaccredeiation1.length > 0 && setaccredeiation1.map(
                  (link1, index1)=>
                  <div key={index1} className='position-relative text-center'><img  className="img-fluid" src={link1.featuredImage.node.sourceUrl}/></div>
                )
              }   
        </Slider>
          {/* <OwlCarousel    
              className="owl-theme logo-slider"  
              {...options} >
              {
                setaccredeiation1 && setaccredeiation1.length > 0 && setaccredeiation1.map(
                  (link1, index1)=>
                  <div key={index1} className='position-relative item'><img  className="img-fluid" src={link1.featuredImage.node.sourceUrl}/></div>
                )
              }            
          </OwlCarousel> */}
        </div>      
      </div>      
    </div>
  )
}

export default Homesection2
