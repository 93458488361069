import React from 'react';

const Homesection5 = (props) => {
  const { statstics1 } = props 
  return (
    <>
    <div className='section-spacing bg-gray'>
        <div className='container'>
          <div className='row'>
          {  statstics1 && statstics1.length > 0 && statstics1.map((link1, index1) =>
            <div key={index1} className='col-lg-3 col-sm-6 col-12 text-center mb-lg-0 mb-3'>
                      <img src={link1.image.sourceUrl} alt={link1.image.title} className='img-fluid statsicon' />
                      <div className='mt-3'>
                      <div className='kvtfont section-title text-blue line-height-normal'>{link1.title}</div>
                      <div className='text-blue'>{link1.description}</div>
                      </div>                     
            </div> 
             ) 
            }               
          </div>
        </div>
    </div>
    </>
  )
}

export default Homesection5
