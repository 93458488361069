import React, { useEffect, useState } from 'react'
import './assets/css/home.css';
import Homesection1 from './components/homepage/Homesection1';
import Homesection2 from './components/homepage/Homesection2';
import Homesection3 from './components/homepage/Homesection3';
import Homesection4 from './components/homepage/Homesection4';
import Homesection5 from './components/homepage/Homesection5';
import Homesection6 from './components/homepage/Homesection6';
import Homesection7 from './components/homepage/Homesection7';
import axios from 'axios';
import Loader from './Loader';
import { ApolloProvider } from '@apollo/client';
import client from './Apollo';

const Home = () => {    

  const [homedata, setHomedata] = useState();
  const [bannerdata, setBannerdata] = useState();
  const [servicestitle, setServicestitle] = useState();
  const [solutiontitle, setSolutiontitle] = useState();
  const [serviceslist, setServiceslist] = useState();
  const [solutionimages, setSolutionimages] = useState();
  const [industrytitle, setIndustrytitle] = useState();
  const [industryimages, setIndustryimages] = useState();
  const [globalreachtitle, setGlobalreachtitle] = useState();
  const [globalreachimage, setGlobalreachimage] = useState();
  const [globalreachcontent, setGlobalreachcontent] = useState();
  const [statstics, setStatstics] = useState();
  const [trustedbytitle, setTrustedbytitle] = useState();
  const [trustedbyimage, setTrustedbyimage] = useState();
  const [casetitle, setCasetitle] = useState();
  const [casestudy, setCasestudy] = useState();
  const [accredeiation, setAccredeiation] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const homedataquery = {
      method:'POST',
      url: 'https://mveximwp.mvexim.in/graphql',
      headers: {
        'content-type': 'application/json'
      },
      data: {
        query:`
        query HomePageQuery {
          page(id: "home-page", idType: URI) {
            homePage {
              bannerSectionDetails {
                bannerImage {
                  altText
                  sourceUrl
                  title
                }
                bannerVideo {
                  link
                  mediaItemUrl
                }
                bannerTitle
                bannerDescription
                bannerButton {
                  target
                  title
                  url
                }
              }
              serviceMainTitle
              selectServices {
                ... on Service {
                  title
                  slug
                  excerpt
                  featuredImage {
                    node {
                      altText
                      title
                      sourceUrl
                    }
                  }
                }
              }
              solutionMainTitle
              solutionDetails {
                solutionImage {
                  altText
                  sourceUrl
                  title
                }
              }
              industryMainTitle
              industryDetails {
                image {
                  sourceUrl
                  title
                  altText
                }
                title
              }
              globalReachMainTitle
              globalReachImage {
                altText
                sourceUrl
                title
                uri
              }
              globalReachContent
              staticsDetails {
                description
                title
                image {
                  altText
                  sourceUrl
                  title
                }
              }
              trustedByMainTitle
              trustedByDetails {
                image {
                  altText
                  uri
                  title
                  sourceUrl
                }
              }
              caseStudyMainTitle
              selectCaseStudy {
                ... on Casestudy {
                  featuredImage {
                    node {
                      altText
                      uri
                      title
                      sourceUrl
                    }
                  }
                  title
                  excerpt
                  slug
                  caseStudyCats {
                    nodes {
                     name
                    }
                  }
                }
              }
            }
          }
          accredeiations {
            nodes {
              uri
              title
              featuredImage {
                node {
                  altText
                  title
                  sourceUrl
                  slug
                }
              }
            }
          }
        }                   
        `
      }
}

axios.request(homedataquery).then(function (response) {
  setLoading(false);
  //console.log(response.data.data);
  setAccredeiation(response.data.data.accredeiations.nodes)
  setHomedata(response.data.data.page.homePage)    
  setBannerdata(response.data.data.page.homePage.bannerSectionDetails)
  setServicestitle(response.data.data.page.homePage.serviceMainTitle)
  setSolutiontitle(response.data.data.page.homePage.solutionMainTitle)
  setSolutionimages(response.data.data.page.homePage.solutionDetails)
  setIndustrytitle(response.data.data.page.homePage.industryMainTitle)
  setIndustryimages(response.data.data.page.homePage.industryDetails)
  setGlobalreachtitle(response.data.data.page.homePage.globalReachMainTitle)
  setGlobalreachimage(response.data.data.page.homePage.globalReachImage.sourceUrl)
  setGlobalreachcontent(response.data.data.page.homePage.globalReachContent)
  setTrustedbytitle(response.data.data.page.homePage.trustedByMainTitle)
  setTrustedbyimage(response.data.data.page.homePage.trustedByDetails)
  setServiceslist(response.data.data.page.homePage.selectServices)
  setStatstics(response.data.data.page.homePage.staticsDetails)
  setCasetitle(response.data.data.page.homePage.caseStudyMainTitle)
  setCasestudy(response.data.data.page.homePage.selectCaseStudy)
}).catch(function (error) {
  console.error(error);
})
  }, []);

  

  return (
    <>
    <div>
    {loading ? (
        <Loader /> // Display the Loader component while loading
      ) : (
        // Render your data once loading is complete
        <div>
         <Homesection1 bannerdata1={bannerdata}/>
        <Homesection2 setaccredeiation1={accredeiation}  />
        <Homesection3 servicetitle1={servicestitle} doortitle={solutiontitle} doorimages={solutionimages} servicelist1={serviceslist} />
        <Homesection4 industrytitle1={industrytitle} industryimages1={industryimages} golbaltitle={globalreachtitle} golbalimage={globalreachimage} golbalcontent={globalreachcontent} />
        <Homesection5 statstics1={statstics}/>
        <Homesection6 trustedbytitle1={trustedbytitle} trustedbyimage1={trustedbyimage} casetitle1={casetitle} casestudy1={casestudy} />
        <ApolloProvider client={client}>
          <Homesection7/>
        </ApolloProvider>
       
        </div>
      )}
      
    </div>
    </>
  )
}

export default Home
