import React, { useEffect, useState } from 'react'
import Loader from './Loader';
import axios from 'axios';
import Homesection7 from './components/homepage/Homesection7';
import { useParams } from 'react-router-dom';

const Casestudy = () => {
  window.scrollTo(0, 0);
  const { slug } = useParams();
  const [singlecasestudydata, setSinglecasestudydata] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(()=>{

    const casestudy = {
      method:'POST',
      url: 'https://mveximwp.mvexim.in/graphql',
      headers: {
        'content-type': 'application/json'
      },
      data: {
        query:`
        query NewQuery {
          casestudyBy(slug: "${slug}"){
              title
              content
              caseStudyCats {
                nodes {
                  name
                }
              }
            }
        }                 
        `
      }
}

axios.request(casestudy).then(function (response) {
  setLoading(false);
  //console.log(response.data.data); 
  setSinglecasestudydata(response.data.data)
}).catch(function (error) {
  console.error(error);
})

  }, []);

  return (
    <>
    {loading ? (
        <Loader /> // Display the Loader component while loading
      ) : (
        // Render your data once loading is complete
        <div>
         <div className='section-spacing'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
                <div className='section-title mb-3'>{singlecasestudydata.casestudyBy.title}</div>
                <div  dangerouslySetInnerHTML={{ __html: singlecasestudydata.casestudyBy.content }} />
            </div>            
          </div>
        </div>
    </div>

       
          <Homesection7/>
       
        </div>
      )}
    
    </>
  )
}

export default Casestudy
