import React, { useState, useEffect } from 'react';
import icon1 from '../assets/images/location.svg';
import icon2 from '../assets/images/mail.svg';
import icon3 from '../assets/images/phone.svg';
import socialicon from '../assets/images/facebook.svg';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Loader from '../Loader';
import Slider from 'react-slick';

const Footer = () => {
 
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [datasub, setDatasub] = useState();
  const [data1, setData1] = useState();
  const [data1sub, setData1sub] = useState();
  const [col4sub, setCol4sub] = useState();
  const [col5, setCol5] = useState();
  const [col5sub, setCol5sub] = useState();
  const [footeraccrediation, setFooteraccrediation] = useState();
 
  

    useEffect(() => {
      const options = {
        method: 'POST',
        url: 'https://mveximwp.mvexim.in/graphql',
        headers: {
          'content-type': 'application/json'
        },
        data: {
          query: `query FooterCol2Query {
            page(idType: URI, id: "general-theme-settings") {
              acfthemeOptions {
                selectAccredeiations {
                  ... on Accredeiation {
                    featuredImage {
                      node {
                        altText
                        title
                        sourceUrl
                      }
                    }
                  }
                }
              }          
              acfthemeOptions {
                footerColumn2Lable
                footerColumn3Lable
                footerLogo {
                  altText
                  title
                  sourceUrl
                }
                description
                socialIconsLable
                socialIcons {
                  label
                  link
                  logo {
                    altText
                    title
                    sourceUrl
                  }
                }
                footerColumn4Lable
                address
                email
                email2
                phoneNumberDetails {
                  phoneNumber
                }
                footerCopyright
                footerTermsLinksDetails {
                  footerTermsLinks {
                    target
                    title
                    url
                  }
                }
              }          
            }                        
            menuItems(where: {location: FOOTER_QUICK_MENU}) {
              nodes {
                uri
                url
                title
                label
              }
            }        
          }
          `
        },
        
      };
      const options1 = {
        method: 'POST',
        url: 'https://mveximwp.mvexim.in/graphql',
        headers: {
          'content-type': 'application/json'
        },
        data: {
          query: `query FooterCol3Query {       
            menuItems(where: {location: FOOTER_SERVICE_MENU}) {
              nodes {
                uri
                url
                title
                label
              }
            }
          }     
          `		}
      };
    
       
    
      axios
        .request(options)
        .then(function (response) {
          //console.log(response.data.data);
          setData(response.data.data)
          setDatasub(response.data.data.menuItems.nodes)
          setCol4sub(response.data.data.page.acfthemeOptions.phoneNumberDetails)
          setData1(response.data.data.page.acfthemeOptions.socialIcons)
          setCol5sub(response.data.data.page.acfthemeOptions.footerTermsLinksDetails)
          setFooteraccrediation(response.data.data.page.acfthemeOptions.selectAccredeiations)
          
        })
        .catch(function (error) {
          console.error(error);
        }); 
    
        axios
        .request(options1)
        .then(function (response) {
          setData1sub(response.data.data.menuItems.nodes)
          setLoading(false);
        })
        .catch(function (error) {
          console.error(error);
        }); 
    }, []);
   
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow:1,
      slidesToScroll: 1,
      arrows: false,   
      autoplay: true,
      autoplaySpeed: 1000,      
    };

  return (
    <>

{loading ? (
        <Loader /> // Display the Loader component while loading
      ) : (
        // Render your data once loading is complete
        <footer className='section-spacing footer-bg text-white overflow-hidden'>
        <div className='container'>
            <div className='row'>
              <div className='col-12 col-xl-3 mb-xl-0 mb-4'>
                

              {data && (
        <div>
          <img src={data.page.acfthemeOptions.footerLogo.sourceUrl} className='img-fluid mb-4' alt={data.page.acfthemeOptions.footerLogo.altText} title={data.page.acfthemeOptions.footerLogo.title} /> 
          <div dangerouslySetInnerHTML={{ __html: data.page.acfthemeOptions.description }} /> 
          <div className='kvtfont pt-4 fw-bold'>{data.page.acfthemeOptions.socialIconsLable}</div>
        </div>
      )}             
                  
                  
                  <ul className='social-list mt-2'>  
                  {data1 && data1.length > 0
                        && data1.map
                        (
                        (links1, index1) => 
                        <li key={index1}><Link to={links1.link} className='text-white text-decoration-none usefulllink'><img src={links1.logo.sourceUrl} alt={links1.label}  /></Link></li>
                        )
                        }                 
                   
                  </ul>
              </div>
              <div className='col-12 col-xl-9'>
                <div className='row'>
                    <div className='col-lg-2 col-md-6 col-sm-6 col-6 mb-lg-0 mb-3'>
                    {data && (
        <div>
          <div className='kvtfont fw-20 footer-title'>{data.page.acfthemeOptions.footerColumn2Lable}</div>
         
        </div>
      )} 
                  <ul className='footer-list'>  
                    {datasub && datasub.length > 0
                        && datasub.map
                        (
                        (links1, index1) => 
                        <li key={index1}><Link to={links1.url} className='text-white text-decoration-none'>{links1.label}</Link></li>
                        )
                        }                
                  
                    </ul>
                    
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-6 col-6 mb-lg-0 mb-3'>
                    {data && (
        <div>
          <div className='kvtfont fw-20 footer-title'>{data.page.acfthemeOptions.footerColumn3Lable}</div>
         
        </div>
      )} 
                  <ul className='footer-list'>  
                  <ul className='footer-list'>  
                    {data1sub && data1sub.length > 0
                        && data1sub.map
                        (
                        (links1, index1) => 
                        <li key={index1}><Link to={links1.url} className='text-white text-decoration-none'>{links1.label}</Link></li>
                        )
                       }                
                  
                    </ul>
                  
                    </ul>
                    </div>
                    <div className='col-lg-4 col-md-6 col-12'>
                    {data && (
                      <>
                      <div>
                      <div className='kvtfont fw-20 footer-title'>{data.page.acfthemeOptions.footerColumn4Lable}</div>
                      <div className='d-flex footer-conact-deatils mb-3'>
                          <div className='icondiv'>
                            <img src={icon1} alt='Contact' />
                          </div>
                          <div className='contentdiv'>
                            <div dangerouslySetInnerHTML={{ __html: data.page.acfthemeOptions.address }} />                            
                          </div>
                      </div>
                      <div className='d-flex footer-conact-deatils mb-3'>
                          <div className='icondiv'>
                            <img src={icon2} alt='Email' />
                          </div>
                          <div className='contentdiv'>
                            <Link to={"mailto:" + data.page.acfthemeOptions.email} className='text-white text-decoration-none'>{data.page.acfthemeOptions.email}</Link><br/>
                            <Link to={"mailto:" + data.page.acfthemeOptions.email2} className='text-white text-decoration-none'>{data.page.acfthemeOptions.email2}</Link>                                                    
                          </div>
                      </div> </div>                     
                      </>
                     )}  

                        <div className='d-flex footer-conact-deatils mb-3'>
                            <div className='icondiv'>
                              <img src={icon3} alt='Phonenumber' />
                            </div>
                            <div className='contentdiv'>
                              <div>
                              {col4sub && col4sub.length > 0
                        && col4sub.map
                        (
                        (links1, index1) => 
                        <Link key={index1} to={"tel:" + links1.phoneNumber} className='text-white text-decoration-none d-block'>{links1.phoneNumber}</Link>
                        )
                        }                             
                              </div>
                            </div>
                        </div>
                      
                      
                      
                    </div>
                    <div className='col-lg-3 col-md-6 col-12'>
                    <div className='kvtfont fw-20 footer-title'>Accreditions</div>
                        <div className='footer-logos-main d-flex flex-wrap'>
                        {
                          footeraccrediation && footeraccrediation.length > 0 && footeraccrediation.map(
                            (link1, index1)=>
                            <div key={index1} className='position-relative text-center overflow-hidden footer-accreditions-logo'><img  className="img-fluid" src={link1.featuredImage.node.sourceUrl}/></div>
                          )
                        }  
                        </div> 
                    </div>
                </div>
              </div>             
              
            </div>
        </div>
        <div className='container copy-text mt-5'>
          <div className='row'>
              <div className='col-lg-6 col-12 mb-lg-0 mb-2'>
              {data && (
        <div>
          <div dangerouslySetInnerHTML={{ __html: data.page.acfthemeOptions.footerCopyright }} />
         
        </div>
      )} 
              </div>
              <div className='col-lg-6 col-12 text-lg-end'>
                <div className='termlinks'>
                {col5sub && col5sub.length > 0
                        && col5sub.map
                        (
                        (links1, index1) => 
                        <Link key={index1} to={links1.footerTermsLinks.url} className='text-white text-decoration-none'>{links1.footerTermsLinks.title}</Link>
                        )
                        } 
                  </div>                
              </div>
          </div>
        </div>
      </footer>
      )}

      
    </>
  )
}

export default Footer
