import React, { useEffect, useRef, useState } from 'react'
import tabimg from '../../assets/images/01.svg'
import { useParams } from 'react-router-dom'

const Servicesection2 = (props) => {
 
  const { servicedata1 } =props
  const {sservicebbgimage}=props
  const { strategyContent } =props
  const {strategyDetails}=props
  const {strategyTitle}=props

  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const [scrollPosition, setScrollPosition] = useState(0);

  const scrollRef = useRef(null);



  useEffect(() => {
  
    function tabbing() {
        // Get the first element with the class 'tabbingsectionbg'
  const element = document.querySelector('.tabbingsectionbg');
  // Check if the element is found
  if (element) {
    // Access the scrollTop property of the element
    const position = element.offsetTop - 100;
    // Set the scroll position state
    setScrollPosition(position);
  }

  const urlhas = window.location.href.split("#")[1];



if (urlhas == 'india-uk-tradelane') {

  var parentElement = document.querySelector('.servicetoshow');
  var parentElement1 = document.querySelector('.customscrollcss');
  var nthChildIndex = 7; // Change this to the desired nth-child index (1-based)
  
  // Select the nth child and add the 'highlight' class
  var nthChild = parentElement.children[nthChildIndex - 1];
  var nthChild1 = parentElement1.children[nthChildIndex - 1];
  const position1 = document.querySelector('.tabbingsectionbg').offsetTop - 100;
  document.querySelector('.servicetoshow>div').classList.remove('active');
  document.querySelector('.rectangular-box').classList.remove('active');
  
  if (nthChild) {
    
    nthChild.classList.add('active');
    nthChild1.classList.add('active');
    setScrollPosition(position1);
    window.scrollTo({ top: scrollPosition, behavior: 'smooth' }, 1300);
    
  }
    } 
}
tabbing();

  }, []);

  const openLightbox = index => {
    setCurrentImage(index);
    setLightboxIsOpen(true);  
    var parentElement = document.querySelector('.servicetoshow');
    var parentElement1 = document.querySelector('.customscrollcss');
  var nthChildIndex = 7; // Change this to the desired nth-child index (1-based)
   // Select the nth child and add the 'highlight' class
   var nthChild = parentElement.children[nthChildIndex - 1];
   var nthChild1 = parentElement1.children[nthChildIndex - 1];
    nthChild.classList.remove('active');
    nthChild1.classList.remove('active');
    // Remove the fragment identifier from the URL
    const currentUrl = window.location.href;
    const updatedUrl = currentUrl.split('#')[0];
    window.history.replaceState({}, document.title, updatedUrl);

    window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    
    
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setLightboxIsOpen(false);
  };
 

  //console.log(strategyDetails);
  return (
    <>
        <div className='section-spacing bluebg text-white'>
            <div className='container'>
              <div className='section-title text-white mb-1'>{strategyTitle}</div>
              <div dangerouslySetInnerHTML={{ __html: strategyContent }} /> 
              <div className='row mt-4'>
                {strategyDetails && strategyDetails.length>0 && strategyDetails.map((link,index)=>
                  <div key={index} className='col-md-3 col-sm-6 col-12 mb-3 text-center'>
                      <div className='mb-2'><img src={link.icon.sourceUrl} className='img-fluid stratargyicon' /></div>
                      <div className='sub-title'>{link.title}</div>
                  </div>
                )}
              </div>
            </div>
        </div>
        <div className='section-spacing homeservice'>
            <div className='container'>
                <div className='row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-xl-4 row-cols-lg-2 customscrollcss'>
                  {
                    servicedata1 && servicedata1.length > 0 && servicedata1.map((links1, index1)=>
                    <div key={index1} className='col mb-3'>
                    <div className={`${index1 == currentImage ? 'rectangular-box text-center active' : 'rectangular-box text-center'}`} onClick={() => openLightbox(index1)}>                                       
                        <div className='mb-3'><img src={links1.featuredImage.node.sourceUrl}  alt={links1.featuredImage.node.title} /></div>
                        <div className='service-title text-white'>{links1.title}</div>                        
                    </div>
                  </div>     
                    ) 
                  }
                                
                </div>
            </div>        
          </div>

        <div className='section-spacing tabbingsectionbg overflow-x-hidden position-relative' ref={scrollRef}>
            <img src={sservicebbgimage.sourceUrl} alt='Serviebg' className='img-fluid servicebg' />
            <div className='container position-relative z-index10'>
            <div className='servicetoshow' id="serviceAccordion">
        {servicedata1 && servicedata1.length>0 && servicedata1.map((service, index) => (  
          <div key={index} className={`${index == currentImage ? 'active' : ''}`}>
              {/* <div className='bluebg p-4 text-white servicetitle'><img src={service.featuredImage.node.sourceUrl} className='img-fluid' alt={service.featuredImage.node.title} />{service.title}</div> */}
              <div dangerouslySetInnerHTML={{ __html: service.content }} /> 
          </div>
        ))}
        </div>
            </div>

             
                {/* <div  className='row gx-lg-5'>
                <div className='col-lg-3 col-12'>
                  <ul className="nav nav-tabs d-none d-lg-flex flex-column customtabsservices" id="myTab" role="tablist">
                  { servicedata1 && servicedata1.length>0 && servicedata1.map((link1,index1)=>                
                    <li key={index1} className="nav-item" role="presentation">
                      <button className="nav-link" id={`${link1.title.split(" ").join("")}`} data-bs-toggle="tab" data-bs-target={`${link1.title.split(" ").join("")}-pane`} type="button" role="tab" aria-controls={`${link1.title.split(" ").join("")}-pane`} aria-selected="true"><img src={link1.featuredImage.node.sourceUrl} className='img-fluid' alt={link1.featuredImage.node.altText} /> {link1.title}</button>
                    </li> 
                  )}                                       
                  </ul>
                </div>
                <div className='col-lg-6 col-12'>
                <div className="tab-content accordion" id="myTabContent">
                { servicedata1 && servicedata1.length>0 && servicedata1.map((link,index)=>
                        <div key={index} className={`tab-pane fade accordion-item`} id={`${link.title.split(" ").join("")}-pane`} role="tabpanel" aria-labelledby={`${link.title.split(" ").join("")}`} tabIndex="0">
                        <h2 className="accordion-header d-lg-none" id="headingOne">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"><img src={link.featuredImage.node.sourceUrl} className='img-fluid' alt={link.featuredImage.node.altText} /> {link.title}</button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show  d-lg-block" aria-labelledby="headingOne" data-bs-parent="#myTabContent">
                          <div className="accordion-body">
                            <div className='section-title text-blue mb-4'>
                            {link.title}
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: link.content }} />                            
                          </div>
                        </div>

                        </div>
                )}                  
                  
                 
                </div>
                </div>
                <div className='col-lg-3 col-12'>
                  <ul className="nav nav-tabs d-none d-lg-flex flex-column" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="first-tab" data-bs-toggle="tab" data-bs-target="#first-tab-pane" type="button" role="tab" aria-controls="first-tab-pane" aria-selected="true">Home</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="second-tab" data-bs-toggle="tab" data-bs-target="#second-tab-pane" type="button" role="tab" aria-controls="second-tab-pane" aria-selected="false">Profile</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="third-tab" data-bs-toggle="tab" data-bs-target="#third-tab-pane" type="button" role="tab" aria-controls="third-tab-pane" aria-selected="false">Contact</button>
                    </li>
                  </ul>
                </div>
                </div> */}
              {/* <div className='row gx-lg-5'>
                  <div className='col-lg-3 col-12'>
                    <ul className="nav nav-tabs d-none d-lg-flex flex-column customtabsservices" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="first-tab" data-bs-toggle="tab" data-bs-target="#first-tab-pane" type="button" role="tab" aria-controls="first-tab-pane" aria-selected="true"><img src='https://multiverse.developstaging.com/wp-content/uploads/2023/09/service-icon-1.svg' className='img-fluid' alt='' /> Ocean<br/>Freight</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="second-tab" data-bs-toggle="tab" data-bs-target="#second-tab-pane" type="button" role="tab" aria-controls="second-tab-pane" aria-selected="false"><img src={tabimg} className='img-fluid' alt='' /> Inland<br/>Services</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="third-tab" data-bs-toggle="tab" data-bs-target="#third-tab-pane" type="button" role="tab" aria-controls="third-tab-pane" aria-selected="false"><img src={tabimg} className='img-fluid' alt='' /> Air<br/>Freight</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="fourth-tab" data-bs-toggle="tab" data-bs-target="#fourth-tab-pane" type="button" role="tab" aria-controls="fourth-tab-pane" aria-selected="false"><img src={tabimg} className='img-fluid' alt='' /> Customs<br/>Clearance</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="fifth-tab" data-bs-toggle="tab" data-bs-target="#fifth-tab-pane" type="button" role="tab" aria-controls="fifth-tab-pane" aria-selected="false"><img src={tabimg} className='img-fluid' alt='' /> Value Added</button>
                      </li>
                    </ul>
                  </div>
                  <div className='col-lg-6 col-12'>
                  <div className="tab-content accordion" id="myTabContent">
                    <div className="tab-pane fade show active accordion-item" id="first-tab-pane" role="tabpanel" aria-labelledby="first-tab" tabIndex="0">

                      <h2 className="accordion-header d-lg-none" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Ocean<br/>Freight</button>
                      </h2>
                      <div id="collapseOne" className="accordion-collapse collapse show  d-lg-block" aria-labelledby="headingOne" data-bs-parent="#myTabContent">
                        <div className="accordion-body">
                          <div className='section-title text-blue mb-4'>
                           Ocean Freight
                          </div>
                          <ul className='tablist'>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                          </ul>
                        </div>
                      </div>

                    </div>
                    <div className="tab-pane fade accordion-item" id="second-tab-pane" role="tabpanel" aria-labelledby="second-tab" tabIndex="0">
                      <h2 className="accordion-header d-lg-none" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Inland Services
                        </button>
                      </h2>
                      <div id="collapseTwo" className="accordion-collapse collapse d-lg-block" aria-labelledby="headingTwo" data-bs-parent="#myTabContent">
                        <div className="accordion-body">
                        <div className='section-title text-blue mb-4'>
                        Inland Services
                          </div>
                          <ul className='tablist'>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade accordion-item" id="third-tab-pane" role="tabpanel" aria-labelledby="third-tab" tabIndex="0">
                      <h2 className="accordion-header d-lg-none" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Air Freight
                        </button>
                      </h2>
                      <div id="collapseThree" className="accordion-collapse collapse d-lg-block" aria-labelledby="headingThree" data-bs-parent="#myTabContent">
                        <div className="accordion-body">
                        <div className='section-title text-blue mb-4'>
                        Air Freight
                          </div>
                          <ul className='tablist'>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade accordion-item" id="fourth-tab-pane" role="tabpanel" aria-labelledby="fourth-tab" tabIndex="0">
                      <h2 className="accordion-header d-lg-none" id="headingfourth">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefourth" aria-expanded="false" aria-controls="collapsefourth">
                        Customs Clearance
                        </button>
                      </h2>
                      <div id="collapsefourth" className="accordion-collapse collapse d-lg-block" aria-labelledby="headingfourth" data-bs-parent="#myTabContent">
                        <div className="accordion-body">
                        <div className='section-title text-blue mb-4'>
                        Customs Clearance
                          </div>
                          <ul className='tablist'>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade accordion-item" id="fifth-tab-pane" role="tabpanel" aria-labelledby="fifth-tab" tabIndex="0">
                      <h2 className="accordion-header d-lg-none" id="headingfifth">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefifth" aria-expanded="false" aria-controls="collapsefifth">
                        Value Added
                        </button>
                      </h2>
                      <div id="collapsefifth" className="accordion-collapse collapse d-lg-block" aria-labelledby="headingfifth" data-bs-parent="#myTabContent">
                        <div className="accordion-body">
                        <div className='section-title text-blue mb-4'>
                        Value Added
                          </div>
                          <ul className='tablist'>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade accordion-item" id="sixth-tab-pane" role="tabpanel" aria-labelledby="sixth-tab" tabIndex="0">
                      <h2 className="accordion-header d-lg-none" id="headingsixth">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesixth" aria-expanded="false" aria-controls="collapsesixth">
                        E-Commerce
                        </button>
                      </h2>
                      <div id="collapsesixth" className="accordion-collapse collapse d-lg-block" aria-labelledby="headingsixth" data-bs-parent="#myTabContent">
                        <div className="accordion-body">
                        <div className='section-title text-blue mb-4'>
                        E-Commerce
                          </div>
                          <ul className='tablist'>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade accordion-item" id="seventh-tab-pane" role="tabpanel" aria-labelledby="seventh-tab" tabIndex="0">
                      <h2 className="accordion-header d-lg-none" id="headingseventh">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseventh" aria-expanded="false" aria-controls="collapseseventh">
                        Express Network
                        </button>
                      </h2>
                      <div id="collapseseventh" className="accordion-collapse collapse d-lg-block" aria-labelledby="headingseventh" data-bs-parent="#myTabContent">
                        <div className="accordion-body">
                        <div className='section-title text-blue mb-4'>
                        Express Network
                          </div>
                          <ul className='tablist'>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade accordion-item" id="eight-tab-pane" role="tabpanel" aria-labelledby="eight-tab" tabIndex="0">
                      <h2 className="accordion-header d-lg-none" id="headingeight">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
                        Project Cargo
                        </button>
                      </h2>
                      <div id="collapseeight" className="accordion-collapse collapse d-lg-block" aria-labelledby="headingeight" data-bs-parent="#myTabContent">
                        <div className="accordion-body">
                        <div className='section-title text-blue mb-4'>
                        Project Cargo
                          </div>
                          <ul className='tablist'>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade accordion-item" id="ninth-tab-pane" role="tabpanel" aria-labelledby="ninth-tab" tabIndex="0">
                      <h2 className="accordion-header d-lg-none" id="headingninth">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseninth" aria-expanded="false" aria-controls="collapseninth">
                        Contract Services
                        </button>
                      </h2>
                      <div id="collapseninth" className="accordion-collapse collapse d-lg-block" aria-labelledby="headingninth" data-bs-parent="#myTabContent">
                        <div className="accordion-body">
                        <div className='section-title text-blue mb-4'>
                        Contract Services
                          </div>
                          <ul className='tablist'>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade accordion-item" id="tenth-tab-pane" role="tabpanel" aria-labelledby="tenth-tab" tabIndex="0">
                      <h2 className="accordion-header d-lg-none" id="headingtenth">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetenth" aria-expanded="false" aria-controls="collapsetenth">
                        Storage Logistics
                        </button>
                      </h2>
                      <div id="collapsetenth" className="accordion-collapse collapse d-lg-block" aria-labelledby="headingtenth" data-bs-parent="#myTabContent">
                        <div className="accordion-body">
                        <div className='section-title text-blue mb-4'>
                        Storage Logistics
                          </div>
                          <ul className='tablist'>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                            <li><b className='text-blue kvcfont'>Rail Freight</b><br/>Inland haulage facilitation via single wagon/ intermodal transporation from ICDs</li>
                          </ul>
                        </div>
                      </div>
                    </div>


                  </div>
                  </div>
                  <div className='col-lg-3 col-12'>
                  <ul className="nav nav-tabs d-none d-lg-flex flex-column customtabsservices righttabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="sixth-tab" data-bs-toggle="tab" data-bs-target="#sixth-tab-pane" type="button" role="tab" aria-controls="sixth-tab-pane" aria-selected="true"><img src={tabimg} className='img-fluid' alt='' /> E-Commerce</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="seventh-tab" data-bs-toggle="tab" data-bs-target="#seventh-tab-pane" type="button" role="tab" aria-controls="seventh-tab-pane" aria-selected="false"><img src={tabimg} className='img-fluid' alt='' /> Express<br/>Network</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="eight-tab" data-bs-toggle="tab" data-bs-target="#eight-tab-pane" type="button" role="tab" aria-controls="eight-tab-pane" aria-selected="false"><img src={tabimg} className='img-fluid' alt='' /> Project<br/>Cargo</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="ninth-tab" data-bs-toggle="tab" data-bs-target="#ninth-tab-pane" type="button" role="tab" aria-controls="ninth-tab-pane" aria-selected="false"><img src={tabimg} className='img-fluid' alt='' /> Contract<br/>Services</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="tenth-tab" data-bs-toggle="tab" data-bs-target="#tenth-tab-pane" type="button" role="tab" aria-controls="tenth-tab-pane" aria-selected="false"><img src={tabimg} className='img-fluid' alt='' /> Storage<br/>Logistics</button>
                      </li>
                    </ul>
                  </div>
              </div>             */}
 {/* <div className='container customz-index' dangerouslySetInnerHTML={{ __html: servicedata.serviceDetail }} />  */}


        </div>
    </>
  )
}

export default Servicesection2
