import React from 'react'

const Aboutsection2 = (props) => {
  const { contentdetails } = props
  const { contentimage } = props
  const { belowcontent1 } = props
  const divStyle = {
    background: `url("${contentimage.sourceUrl}")`,
    backgroundSize: 'cover', // You can adjust these properties based on your needs
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };

  return (
    <>
    <div className="bg-gray section-spacing aboutbg position-relative" style={divStyle}> 
    <div className="container position-relative z-index10">
            <div className="row align-items-center">               
                <div className="transport-inner-content mt-4 mt-lg-5">
                <div dangerouslySetInnerHTML={{ __html: contentdetails }} /> 
                </div>
            </div>
    </div>
    </div>

    <div className="section-spacing position-relative teamsectionabout"> 
    <div className="container position-relative z-index10">
            <div className="row align-items-center">               
                <div className="transport-inner-content mt-4 mt-lg-5">
                <div dangerouslySetInnerHTML={{ __html: belowcontent1 }} /> 
                </div>
            </div>
    </div>
    </div>

    
    </>
  )
}

export default Aboutsection2
