import React from 'react'
import previcon from '../../assets/images/prev-white.svg';
import nexticon from '../../assets/images/next-white.svg'
import { Link } from 'react-router-dom';
import Slider from "react-slick";


const Homesection1 = (props) => {
  const { bannerdata1 } = props;  
  //console.log(bannerdata1);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: (
      <img src={nexticon} />
    ), 
    nextArrow:(
      <img src={previcon} />
    )
  };

  return (
    <>
    <div>

    <Slider className='banner-slider' {...settings}>
        {bannerdata1 && bannerdata1.length > 0
                      && bannerdata1.map
                      (
                      (links1, index1) => 
                      <div key={index1} className='position-relative itemcaption-content-main'> 
                      <video className="bannervideo" autoPlay playsInline loop muted>
                        <source src={links1.bannerVideo.mediaItemUrl} type="video/mp4" />
                      </video>                     
                        
                        <div className='itemcaption-content container text-center text-white'>
                          <h1 className='sub-title fw-bold'>{links1.bannerTitle}</h1>
                          <div dangerouslySetInnerHTML={{ __html: links1.bannerDescription }} /> 
                          <Link to={links1.bannerButton.url} className='btn btn-primary'>{links1.bannerButton.title}</Link>
                        </div>
                      </div>                        
                      )} 
        </Slider>
     {/* <OwlCarousel items={1}  
          className="owl-theme banner-slider"  
          loop  
          nav  
          margin={0}
          dots={false}
          navText={["<img src="+`${previcon}`+">","<img src="+`${nexticon}`+">"]} >            
           {bannerdata1 && bannerdata1.length > 0
                        && bannerdata1.map
                        (
                        (links1, index1) => 
                        <div key={index1} className='position-relative itemcaption-content-main item'>
                          <img  className="img-fluid banner-img" src={links1.bannerImage.sourceUrl} alt={links1.bannerImage.altText} />
                          <div className='itemcaption-content container text-center text-white'>
                            <h1 className='page-title text-uppercase'>{links1.bannerTitle}</h1>
                            <div dangerouslySetInnerHTML={{ __html: links1.bannerDescription }} /> 

                            <Link to={links1.bannerButton.url} className='btn btn-primary'>{links1.bannerButton.title}</Link>
                          </div>
                        </div>                        
                        )}  
            
      </OwlCarousel>  */}
      
    </div>
    </>
  )
}

export default Homesection1
