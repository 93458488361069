import React, { useState } from 'react'
import Loader from './Loader';
import Homesection7 from './components/homepage/Homesection7';
import axios from 'axios';
import Contactsection1 from './components/contactpage/Contactsection1';
import Contactsection2 from './components/contactpage/Contactsection2';
import '../src/assets/css/contact.css'
import { ApolloProvider } from '@apollo/client';
import client from './Apollo';
import { useEffect } from 'react';

const Contact = () => {
  window.scrollTo(0, 0);
  const [loading, setLoading] = useState(true);
  const [bannerdata,setbannerdata] = useState();
  const [contactdata, setContactdata] = useState();


  useEffect(() => {
    const contactquery = {
      method:'POST',
      url: 'https://mveximwp.mvexim.in/graphql',
      headers: {
        'content-type': 'application/json'
      },
      data: {
        query:`
        query ContactUsPagequery {
          page(id: "contact-us", idType: URI) {
            bannerSection {
              bannerImage {
                altText
                title
                sourceUrl
              }
              bannerDescription
              bannerTitle
            }
            contactUsPage {
              contactDetailsBackgroundImage {
                altText
                title
                sourceUrl
              }
              contactLocationDetails {
                locationName
                contactDetails {
                  contactAddressTitle
                  contactAddress
                  contactAddressDetails {
                    content
                    title
                    icon {
                      altText
                      title
                      sourceUrl
                    }
                  }
                  contactBackgroundImage {
                    altText
                    title
                    sourceUrl
                  }
                }
                
              }
            }
          }
        }
        `
      }
  }
  
  axios.request(contactquery).then(function (response) {
    //console.log(response.data.data);
    setbannerdata(response.data.data.page.bannerSection) 
    setContactdata(response.data.data.page.contactUsPage)
    setLoading(false);
    }).catch(function (error) {
    console.error(error);
    })
  }, []);

 

  return (
    <>
    {loading ? (
        <Loader /> // Display the Loader component while loading
      ) : (
        // Render your data once loading is complete
        <div>
        {/* <Contactsection1 bannerdata={bannerdata} /> */}
        <Contactsection2 contactdata={contactdata}/> 
        <Homesection7/>
               
        </div>
      )}
    </>
  )
}

export default Contact
