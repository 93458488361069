import React from 'react' 
import previcon from '../../assets/images/prev-black.svg';
import nexticon from '../../assets/images/next-black.svg';
import Slider from "react-slick";

const Aboutsction3 = (props) => {
  const {accrimage1} = props
  const {accrtitle1} = props
  // console.log(accrimage1);
  // console.log(accrtitle1);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: (
      <img src={nexticon} />
    ), 
    nextArrow:(
      <img src={previcon} />
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
            slidesToShow: 6
        }
      },
      {
          breakpoint: 992,
          settings: {
              slidesToShow: 3
          }
      },
      {
          breakpoint: 450,
          settings: {
              slidesToShow: 2
          }
      },
      {
          breakpoint: 320,
          settings: {
              slidesToShow: 1
          }
      }
  ]
  };

  return (
    <>
    <div className='pt-5 pb-5'>
      <div className='container'>
      <h2 className="section-title mb-4 text-uppercase">{accrtitle1}</h2>
        <div className='customsliderpadding homesection2slider'>
        <Slider className='logo-slider' {...settings}>
        {
                accrimage1 && accrimage1.length > 0 && accrimage1.map(
                  (link1, index1)=>
                  <div key={index1} className='position-relative'><img  className="img-fluid" src={link1.featuredImage.node.sourceUrl}/></div>
                )
              }       
        </Slider>          
        </div>      
      </div>      
    </div>
    </>
  )
}

export default Aboutsction3
