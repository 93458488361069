import React, { useEffect, useState } from 'react';

const Homesection4 = (props) => {

  const { industrytitle1 } = props
  const { industryimages1 } = props
  const { golbaltitle } = props
  const { golbalimage } = props
  const { golbalcontent } = props

 
 

  return (
    <>
    <div className='section-spacing'>
    <div className='container'>
          <h2 className='section-title mb-5 text-uppercase'>{golbaltitle}</h2>          
        </div> 
        <img src={golbalimage} className='img-fluid w-100' alt={golbaltitle} />
        <div className="clicktoshowcontent">
            <div className='container'>
             <div dangerouslySetInnerHTML={{ __html: golbalcontent }} />  
            </div>
        </div>
        <div className='container mt-5 pt-md-5 pb-0'>
            <h2 className='section-title mb-4 text-uppercase'>{industrytitle1}</h2>
            <div className='row text-center row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-7 justify-content-center'>
              {
                  industryimages1 && industryimages1.length > 0 && industryimages1.map((links1, index1)=>
                  <div key={index1} className='col mb-4'>
                    <div className='mb-3'><img src={links1.image.sourceUrl} alt={links1.image.title} /></div>
                    <div className='font-weight-bold kvtfont-industry'>{links1.title}</div>
                  </div>                      
                  ) 
                }               
                                           
            </div>
        </div> 
        
    </div>
    </>
  )
}

export default Homesection4
