import axios from 'axios';
import React, { useState } from 'react';
import Loader from './Loader';
import { useEffect } from 'react';


const Enquirenow = () => {
  const [loading, setLoading] = useState([true]);
  const [formFields, setFormFields] = useState([]);
  const apiUrl = 'https://mveximwp.mvexim.in/multiverseapi/custom/v1/contact-form-fields/717';

  const [step, setStep] = useState(1);
  const [hideFirstThree, setHideFirstThree] = useState(false);

  const [formData, setFormData] = useState({
    PortofLoading: '',
    PortofDischarge: '',
    CargoDetails: '',
    // Add other form fields here
  });

  const nextStep = () => {
    
    if (step === 1) {
      if (!formData.PortofLoading || !formData.PortofDischarge || !formData.CargoDetails) {
        // Display an alert or error message for incomplete fields
        alert('Please fill in all required fields.');
        return;
      }
      setHideFirstThree(true);
    }
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
    setHideFirstThree(false);
  };

  useEffect(() => {
    // Fetch form fields from the WordPress REST API
    axios.get(apiUrl).then((response) => {
      // Extract and set the form fields
      //console.log(response.data);
      setFormFields(response.data);
      setLoading(false)
    });

    

    
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Your code here
      const input = document.getElementById('PhoneNo');    
       const iti = window.intlTelInput(input);
      
    window.intlTelInput(input, {
      separateDialCode: false,
      preferredCountries:["us"],
      hiddenInput: "full",
      placeholderNumberType:"MOBILE",
  autoPlaceholder:"polite",
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
      
    });
   
   
    
    // Attach an event listener to the button

    }, 2000);

    // Clean up the timer when the component unmounts or when the dependencies change
    return () => clearTimeout(timer);
    
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
 

 
  const handleSubmit = (e) => {
    e.preventDefault();
   
    const input = document.getElementById('PhoneNo');    
    const iti = window.intlTelInput(input);

   console.log(document.getElementById('PhoneNo').value);

    const formValues = new FormData(e.target);
  
    // Send form data to your API endpoint
    axios.post('https://mveximwp.mvexim.in/multiverseapi/contact-form-7/v1/contact-forms/717/feedback', formValues)
      .then((response) => {
        alert( response.data.message );
        // Handle the API response (success or error)
        document.getElementById('FirstName').value = '';
        document.getElementById('LastName').value = '';
        document.getElementById('Email').value = '';
        document.getElementById('PhoneNumber').value = '';
        document.getElementById('Message').value = '';
      })
      .catch((error) => {
        // Handle errors
      });
  };
  
 
  return (
    <>
 {loading ? (
        <Loader /> // Display the Loader component while loading
      ) : (
        // Render your data once loading is complete
        <div className="modal fade enquiremodal gallerymodal" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">            
            <div className="modal-body p-3">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <h2 className='sub-title mb-3 text-blue'>Enquire Now</h2>
            <p className='small'>Fields with (*) are required.</p>
        <form onSubmit={handleSubmit} className='kvtfont formfont1 row'>

        {formFields && formFields.length > 0 && formFields.map((field, index) => (
        <div key={field.label}>
          {index < 3 &&  (
            <div className="mb-4" style={{ display: step === 1 ? 'block' : 'none' }}>
              <label htmlFor={field.id}>{field.label}</label>
              {field.label === 'Message' ? (
                <textarea onChange={handleInputChange} type={field.type} id={field.name} name={field.name} className='form-control' />
              ) : (
                <input onChange={handleInputChange} type={field.type} id={field.name} name={field.name} className='form-control' />
              )}
            </div>
          )}

          {index >= 3 && (
            <div className="mb-4" style={{ display: step === 2 ? 'block' : 'none' }}>
              <label htmlFor={field.id}>{field.label}</label>
              {field.label === 'Message' ? (
                <textarea onChange={handleInputChange} type={field.type} id={field.name} name={field.name} className='form-control' />
              ) : (
                <input onChange={handleInputChange} type={field.type} id={field.name} name={field.name} className='form-control' />
              )}
            </div>
          )}
        </div>
      ))}

      <div>
        {step === 1 && (
          <button type="button" className='btn btn-primary' onClick={nextStep}>Next</button>
        )}

        {step === 2 && (
          <>
            <button type="button" className='btn btn-primary' onClick={prevStep}>Previous</button>
            <button type="submit" className="btn btn-yellow text-center mx-2">
              Send the message
              <svg className='buttonarrow' xmlns="http://www.w3.org/2000/svg" id="Component_1_8" data-name="Component 1 – 8" width="16" height="16" viewBox="0 0 16 16">
                <path xmlns="http://www.w3.org/2000/svg" id="Path_33" data-name="Path 33" d="M14.689,6.913H2.95L8.387,1.475A.864.864,0,1,0,7.165.253L.253,7.166a.864.864,0,0,0,0,1.222L7.166,15.3a.864.864,0,1,0,1.222-1.222L2.95,8.641H14.689a.864.864,0,1,0,0-1.728Z" transform="translate(0 0)" fill="#000"/>
              </svg>
            </button>
          </>
        )}
      </div>
        {/* {formFields.map((field) => (

          <div className='col-md-12 col-12' key={field.label}>
            <div className="mb-4">
              <label htmlFor={field.id}>{field.label}</label>
              {field.label === 'Message' ? (
            <textarea type={field.type} id={field.name} name={field.name} className='form-control' />
          ) : <input type={field.type} id={field.name} name={field.name} className='form-control' />}
            </div>
          </div>
        ))}
        <div className='col-12 text-center'>
              <button type="submit" className="btn btn-yellow text-center">Send the message 
<svg className='buttonarrow' xmlns="http://www.w3.org/2000/svg" id="Component_1_8" data-name="Component 1 – 8" width="16" height="16" viewBox="0 0 16 16">
  <path xmlns="http://www.w3.org/2000/svg" id="Path_33" data-name="Path 33" d="M14.689,6.913H2.95L8.387,1.475A.864.864,0,1,0,7.165.253L.253,7.166a.864.864,0,0,0,0,1.222L7.166,15.3a.864.864,0,1,0,1.222-1.222L2.95,8.641H14.689a.864.864,0,1,0,0-1.728Z" transform="translate(0 0)" fill="#000"/>
</svg></button>
              </div> */}
      </form> 
              
            </div>           
          </div>
        </div>
        </div>         
      )}

    </>    
  )
}

export default Enquirenow

