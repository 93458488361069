import React from 'react';
import banner from '../../assets/images/banner.jpg';
import previcon from '../../assets/images/prev-black.svg';
import nexticon from '../../assets/images/next-black.svg';
import nextwhite from '../../assets/images/next-white.svg'
import { Link } from 'react-router-dom';
import Slider from "react-slick";

const Homesection6 = (props) => {

  const {trustedbytitle1} = props;
  const {trustedbyimage1} = props;
  const {casetitle1} = props;
  const {casestudy1} = props;
 
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true, 
    prevArrow: (
      <img src={nexticon} />
    ), 
    nextArrow:(
      <img src={previcon} />
    ),  
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
            slidesToShow: 6
        }
      },
      {
          breakpoint: 992,
          settings: {
              slidesToShow: 3
          }
      },
      {
          breakpoint: 450,
          settings: {
              slidesToShow: 2
          }
      },
      {
          breakpoint: 320,
          settings: {
              slidesToShow: 1
          }
      }
  ]
  };
  

  return (
    <>
    <div className='section-spacing'>
        <div className='container'>
          <h2 className='section-title mb-4 text-uppercase'>{trustedbytitle1}</h2>
          <div className='customsliderpadding '>
          <Slider className='logo-slider' {...settings}>
          {
                trustedbyimage1 && trustedbyimage1.length > 0 && trustedbyimage1.map((link1, index1)=>
                <div key={index1} className='position-relative item'><img  className="img-fluid" src={link1.image.sourceUrl} alt={link1.image.title}/></div>
                ) 
              }    
        </Slider> 
          </div>
                  
        </div> 
        <div className='container mt-5'>
          <div className='d-sm-flex justify-content-between align-items-center mb-3'>
            <h2 className='section-title  text-uppercase'>{casetitle1}</h2>
          </div>          
          <div className='row gx-md-5'>
            {
              casestudy1 && casestudy1.length > 0 && casestudy1.map((link,index)=>
              <div key={index} className='col-lg-4 col-md-6 col-12 mb-4 mb-lg-0'>
                <div className='casediv-main'>
                  <div className='case-img-main position-relative'>
                    <img  className="img-fluid case-img w-100" src={link.featuredImage.node.sourceUrl}/>
                    <div className='hovercontent'>
                    <div dangerouslySetInnerHTML={{ __html: link.excerpt }} />                        
                        <div className='text-end'><Link to={`/casestudy/${link.slug}`}><img src={nextwhite} className='linkicon' alt='Next' /></Link></div>
                    </div>
                  </div>
                  <div className='typofont mt-3'>{link.title}</div>
                  <div className='text-blue fw-20'><i>{link.caseStudyCats.nodes.name}</i></div>
                </div>
            </div>
              )
            }            
          </div>        
        </div>        
    </div>
    </>
  )
}

export default Homesection6
