import React from 'react'

const Aboutsection4 = (props) => {

  const {mvbgimage} = props
  const {mvdetails} = props
  const {mvimage} = props

  console.log(mvdetails);
 
  return (
    <>
    <section className="position-relative">
    <img src={mvbgimage.sourceUrl} alt={mvbgimage.altText} className="img-fluid missionbg-image"/>
        <div className='section-spacing'>
        <div className="container position-relative">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className="vission-image text-center">
                        <img src={mvimage.sourceUrl} alt={mvimage.altText} className="img-fluid"/>
                    </div>
                </div>
                <div className="col-lg-6">
                  { mvdetails && mvdetails.length>0 && mvdetails.map((link1,index1)=>
                    <div key={index1} className="vission-content mt-4 mb-2">
                        <div className="vission-inner-image d-flex align-items-center">
                            <img src={link1.image.sourceUrl} className="img-fluid"/>
                            <h3>{link1.title}</h3>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: link1.content }} />                         
                    </div>
                  )
                  }
                    
                </div>
            </div>
        </div>
        </div>        
    </section>
    </>
  )
}

export default Aboutsection4
