import React from 'react'

const Aboutsection5 = (props) => {

  const {distinguistitle} = props
  const { distinguisdetails } = props
  //console.log(distinguisdetails);
  return (
    <>
    <div className='section-spacing position-relative bg-light-gray'>
    <div className='container'>
      <h2 className="section-title mb-3 mb-md-5 text-uppercase">{distinguistitle}</h2>
      <div className='row gx-sm-5'>  
          {distinguisdetails && distinguisdetails.length>0 && distinguisdetails.map((link1,index1)=>
            <div key={index1} className='col-lg-4 col-md-6 col-12 mb-5'>
            <div className='gradientbox'>
                <div className='d-flex align-items-center mb-3'>
                    <div className='distinguisimg'>
                        <img src={link1.image.sourceUrl} alt={link1.image.altText} />
                    </div>
                    <div className='distinguistitle section-title text-uppercase'>
                    {link1.title}
                    </div>
                </div>
                <p className='mb-0' dangerouslySetInnerHTML={{ __html: link1.content }} />                 
            </div>
          </div>
          )}       
          
      </div>    
    </div> 
    </div>
    </>
  )
}

export default Aboutsection5
