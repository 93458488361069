
import './App.css';
import { BrowserRouter as Router,Routes, Route, NavLink } from 'react-router-dom';  
import Home from './Home';
import About from './About';
import Header from './components/Header';
import Footer from './components/Footer';
import { ApolloProvider } from '@apollo/client';
import client from './Apollo';
import Service from './Service';
import Contact from './Contact';
import Career from './Career';
import BackToTopButton from './BackToTopButton';
import Casestudy from './Casestudy';
import Enquirenow from './Enquirenow';

function App() {
  return (
    <>
    <ApolloProvider client={client}>
    <Router>
    <div className="App">
      <BackToTopButton/>
      <Header/>
      <div className='content-div'>
        <Routes>
            <Route exact path='/' element={<Home/>}></Route>
            <Route exact path='/about-us' element={<About/>}></Route>
            <Route exact path='/services' element={<Service/>}></Route>
            <Route exact path='/careers' element={<Career/>}></Route>
            <Route exact path='/contactus' element={<Contact/>}></Route>
            <Route exact path='/casestudy/:slug' element={<Casestudy/>}></Route>
            <Route exact path='/enquire' element={<Enquirenow/>}></Route>
        </Routes>
      </div>
      <Footer/>
    </div>
    </Router>
    </ApolloProvider>
    
    
    </>
  );
}

export default App;
