import React from 'react'

const Contactsection2 = (props) => {

  const {contactdata} = props
  //console.log(contactdata);
  return (
    <>
      <div className='position-relative section-spacing lightgraybg'>
          <img src={contactdata.contactDetailsBackgroundImage.sourceUrl} className='img-fluid contactbgimage' />
          {
            contactdata.contactLocationDetails && contactdata.contactLocationDetails.length>0 && contactdata.contactLocationDetails.map((key,index)=>
            <div key={index} className='container customzindex'>
              <div className='row mb-3'>
              <div className='fw-bold section-title text-blue mb-3'>{key.locationName}</div>
                {key.contactDetails && key.contactDetails.length>0 && key.contactDetails.map((link1,index1)=>
                <div key={index1} className='col-md-4 mb-2 col-12 contactboxes'>
                    <div className='fw-bold'>{link1.contactAddressTitle}</div>
                    <div dangerouslySetInnerHTML={{ __html: link1.contactAddress }} /> 
                </div>
                )}                  
              </div>
              <div className='row'>
              {key.contactDetails && key.contactDetails.length>0 && key.contactDetails.map((link1,index1)=>
                <div key={index1} className='col-md-4 mb-4 col-12'>
                  {link1.contactAddressDetails && link1.contactAddressDetails.map((link,index)=>
                    <div key={index} className='d-flex contactaction mb-4'>
                      <div className='contacticon'>                        
                      <img src={link.icon.sourceUrl} />
                      </div>
                      <div className='contacticoncontent'>
                          <div className='contacttitle kvcfont'>{link.title}</div>
                          <div dangerouslySetInnerHTML={{ __html: link.content }} /> 
                      </div>
                    </div>
                    )}
                </div>
                )} 
              </div>
          </div>
            )
          }          
          
      </div>
    </>
  )
}

export default Contactsection2
