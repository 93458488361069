import React from 'react'
import { Link } from 'react-router-dom'

const Careersection1 = (props) => {
  const {bannerdata}=props
  return (
    <>
    <div className='about-banner'>
      <img src={bannerdata.bannerImage.sourceUrl} className='img-fluid w-100' alt={bannerdata.bannerImage.bannerTitle} />
      <div className='about-banner-content'>
      <div className='container'>
          <div className='row'>
              <div className='col-12'>                
                    <h1 className='page-title'>{bannerdata.bannerTitle}</h1>
                    <div dangerouslySetInnerHTML={{ __html: bannerdata.bannerDescription }} />                    
                    
                </div>
              </div>
          </div>
      </div>
    </div>
    </>
  )
}

export default Careersection1
