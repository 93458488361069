import React, { useEffect, useState } from 'react'
import dooricon from '../../assets/images/industry.svg';
import dooricon1 from '../../assets/images/door-main-icon.svg';
import { Link } from 'react-router-dom';

const Homesection3 = (props) => {

  const { servicetitle1 } = props;
  const { servicelist1 } = props;   
  const { doortitle }=props;
  const { doorimages }=props;

  //console.log(servicelist1);
  // Get all elements with the "homeservice" class
const elements = document.querySelectorAll(".homeservice");

elements.forEach(function (element) {
 // console.log(element);
  let boxes = element.querySelectorAll('.rectangular-box');
  let maxHeight = 0;

  // Loop through "rectangular-box" elements within the current "homeservice" element
  boxes.forEach(function (box) {
    // Calculate the height of each box and compare with maxHeight
    const boxHeight = box.clientHeight; // Use clientHeight to get the actual rendered height
    if (boxHeight > maxHeight) {
      maxHeight = boxHeight;
    }
  });

  // Set the height of all "rectangular-box" elements within the current "homeservice" element
  boxes.forEach(function (box) {
    box.style.height = maxHeight + 'px';
  });
});

  return (
    <div className='bg-gray section-spacing homeservice'>
        <div className='container'>
            <h2 className='section-title mb-4'>{servicetitle1}</h2>
            <div className='row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-xl-4 row-cols-lg-2'>
              {
                servicelist1 && servicelist1.length > 0 && servicelist1.map((links1, index1)=>
                <div key={index1} className='col mb-3'>
                <div className='rectangular-box text-center'>                                       
                    <div className='mb-3'><img src={links1.featuredImage.node.sourceUrl} alt={links1.featuredImage.node.title} /></div>
                    <div className='service-title text-white'>{links1.title}</div>
                    <div className='hovercontent-homeservicebox' dangerouslySetInnerHTML={{ __html: links1.excerpt }} />
                </div>
              </div>     
                ) 
              }
                             
            </div>
        </div>
        <div className='container mt-5'>
        <h2 className='section-title mb-4'>{doortitle}</h2>
          <ul className='doorlist'>
          {
                doorimages && doorimages.length > 0 && doorimages.map((links1, index1)=>
                  <li key={index1}><img src={links1.solutionImage.sourceUrl} className='img-fluid' alt={links1.solutionImage.title} /></li>  
                ) 
              }
            
          </ul>
          <div className='mt-5 text-center'><Link className="btn btn-yellow text-center" to="" data-bs-toggle="modal" data-bs-target="#exampleModal1">Enquire Now <svg className='buttonarrow' xmlns="http://www.w3.org/2000/svg" id="Component_1_8" data-name="Component 1 – 8" width="16" height="16" viewBox="0 0 16 16">
  <path xmlns="http://www.w3.org/2000/svg" id="Path_33" data-name="Path 33" d="M14.689,6.913H2.95L8.387,1.475A.864.864,0,1,0,7.165.253L.253,7.166a.864.864,0,0,0,0,1.222L7.166,15.3a.864.864,0,1,0,1.222-1.222L2.95,8.641H14.689a.864.864,0,1,0,0-1.728Z" transform="translate(0 0)" fill="#000"/>
</svg></Link></div>
        </div>
    </div>
  )
}

export default Homesection3
