import axios from 'axios';
import React, { useState } from 'react';
import Loader from '../../Loader';
import { useEffect } from 'react';





const Homesection7 = () => {
  const [loading, setLoading] = useState([true]);
  const [formFields, setFormFields] = useState([]);
  const apiUrl = 'https://mveximwp.mvexim.in/multiverseapi/custom/v1/contact-form-fields/295';

  useEffect(() => {
    // Fetch form fields from the WordPress REST API
    axios.get(apiUrl).then((response) => {
      // Extract and set the form fields
      //console.log(response.data);
      setFormFields(response.data);
      setLoading(false)
    });

    

    
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Your code here
      const input = document.getElementById('PhoneNumber');    
       const iti = window.intlTelInput(input);
      
    window.intlTelInput(input, {
      separateDialCode: false,
      preferredCountries:["us"],
      hiddenInput: "full",
      placeholderNumberType:"MOBILE",
  autoPlaceholder:"polite",
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
      
    });
   
   
    
    // Attach an event listener to the button

    }, 2000);

    // Clean up the timer when the component unmounts or when the dependencies change
    return () => clearTimeout(timer);
    
  }, []);

 
 

 
  const handleSubmit = (e) => {
    e.preventDefault();
   
    const input = document.getElementById('PhoneNumber');    
    const iti = window.intlTelInput(input);

   //console.log(document.getElementById('PhoneNumber').value);

    const formValues = new FormData(e.target);


    // Validate required fields
    if (!formValues.get('FirstName') || !formValues.get('LastName') || !formValues.get('email') || !formValues.get('PhoneNumber')) {
      alert('Please fill in all required fields.');
      return;
    }
   
  
    // Send form data to your API endpoint
    axios.post('https://mveximwp.mvexim.in/multiverseapi/contact-form-7/v1/contact-forms/295/feedback', formValues)
      .then((response) => {
        alert( response.data.message );
        // Handle the API response (success or error)
        document.getElementById('FirstName').value = '';
        document.getElementById('LastName').value = '';
        document.getElementById('email').value = '';
        document.getElementById('PhoneNumber').value = '';
        document.getElementById('message').value = '';
      })
      .catch((error) => {
        // Handle errors
      });
  };
  
 
  return (
    <>
 {loading ? (
        <Loader /> // Display the Loader component while loading
      ) : (
        // Render your data once loading is complete
        <div className='section-spacing bg-gray' id='writetous'>
      <div className='container'>
        <h2 className='section-title mb-3 text-uppercase'>Write to us</h2>
        <p className='small'>Fields with (*) are required.</p>
        <form onSubmit={handleSubmit} className='kvtfont formfont row'>
        {formFields.map((field) => (

          <div className='col-md-6 col-12' key={field.label}>
            <div className="mb-4">
              <label htmlFor={field.id}>{field.label}</label>
              {field.label === 'Message' ? (
            <textarea type={field.type} id={field.name} name={field.name} className='form-control' />
          ) : <input type={field.type} id={field.name} name={field.name} className='form-control' />}
            </div>
          </div>
        ))}
        <div className='col-12 text-center'>
              <button type="submit" className="btn btn-yellow text-center">Send the message 
<svg className='buttonarrow' xmlns="http://www.w3.org/2000/svg" id="Component_1_8" data-name="Component 1 – 8" width="16" height="16" viewBox="0 0 16 16">
  <path xmlns="http://www.w3.org/2000/svg" id="Path_33" data-name="Path 33" d="M14.689,6.913H2.95L8.387,1.475A.864.864,0,1,0,7.165.253L.253,7.166a.864.864,0,0,0,0,1.222L7.166,15.3a.864.864,0,1,0,1.222-1.222L2.95,8.641H14.689a.864.864,0,1,0,0-1.728Z" transform="translate(0 0)" fill="#000"/>
</svg></button>
              </div>
      </form>        
      </div>
    </div>
      )}

    </>    
  )
}

export default Homesection7
