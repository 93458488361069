import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import logo from '../assets/images/logo.svg';
import Enquirenow from '../Enquirenow';

const Header = () => {
  
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
   <>   
   <Enquirenow/>
   <header className="bg-white">
      <nav className="navbar navbar-expand-lg navbar-light bg-light header-navigation">
  <div className="container">
    <NavLink className="navbar-brand" to="/"><img src={logo} alt="Test" /></NavLink>
    <button className={`navbar-toggler ${isMenuOpen ? 'collapsed' : ''}`} onClick={toggleMenu} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <svg className={`bars ${isMenuOpen ? 'active' : ''}`} viewBox="0 0 100 100"  >
      <path className="line top" d="m 30,33 h 40 c 13.100415,0 14.380204,31.80258 6.899646,33.421777 -24.612039,5.327373 9.016154,-52.337577 -12.75751,-30.563913 l -28.284272,28.284272"></path>
      <path className="line middle" d="m 70,50 c 0,0 -32.213436,0 -40,0 -7.786564,0 -6.428571,-4.640244 -6.428571,-8.571429 0,-5.895471 6.073743,-11.783399 12.286435,-5.570707 6.212692,6.212692 28.284272,28.284272 28.284272,28.284272"></path>
      <path className="line bottom" d="m 69.575405,67.073826 h -40 c -13.100415,0 -14.380204,-31.80258 -6.899646,-33.421777 24.612039,-5.327373 -9.016154,52.337577 12.75751,30.563913 l 28.284272,-28.284272"></path>
    </svg>
    </button>
    <div className={`collapse navbar-collapse justify-content-end ${isMenuOpen ? 'show' : ''}`}  id="navbarSupportedContent">
      <ul className="navbar-nav">
        <li className="nav-item">
          <NavLink className="nav-link" onClick={closeMenu} to="/about-us">About Us</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" onClick={closeMenu} to="/services">Services</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" onClick={closeMenu} to="/careers">Careers</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" onClick={closeMenu} to="/contactus">Contact Us</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link nav-btn" onClick={closeMenu} data-bs-toggle="modal" data-bs-target="#exampleModal1" to="">Enquire Now <svg className='buttonarrow' xmlns="http://www.w3.org/2000/svg" id="Component_1_8" data-name="Component 1 – 8" width="16" height="16" viewBox="0 0 16 16">
  <path xmlns="http://www.w3.org/2000/svg" id="Path_33" data-name="Path 33" d="M14.689,6.913H2.95L8.387,1.475A.864.864,0,1,0,7.165.253L.253,7.166a.864.864,0,0,0,0,1.222L7.166,15.3a.864.864,0,1,0,1.222-1.222L2.95,8.641H14.689a.864.864,0,1,0,0-1.728Z" transform="translate(0 0)" fill="#000"/>
</svg></NavLink>
        </li>
        {/* <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Dropdown
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a className="dropdown-item" to="#">Action</a></li>
            <li><a className="dropdown-item" to="#">Another action</a></li>
            <li><a className="dropdown-item" to="#">Something else here</a></li>
          </ul>
        </li> */}
      </ul>
    </div>
  </div>
</nav>
      </header>
   </>
  )
}

export default Header
