import React, { useState } from 'react'
import Aboutsection1 from './components/Aboutpage/Aboutsection1';
import './assets/css/about.css';
import Aboutsection2 from './components/Aboutpage/Aboutsection2';
import axios from 'axios';
import Loader from './Loader';
import Aboutsction3 from './components/Aboutpage/Aboutsction3';
import Aboutsection4 from './components/Aboutpage/Aboutsection4';
import Aboutsection5 from './components/Aboutpage/Aboutsection5';
import Aboutsection6 from './components/Aboutpage/Aboutsection6';
import Homesection7 from './components/homepage/Homesection7';
import client from './Apollo';
import { ApolloProvider } from '@apollo/client';
import { useEffect } from 'react';




const About = () => {

  const [loading, setLoading] = useState(true);
  const [bannersection, setBannersection] = useState();
  const [accrimages, setAccrimages] = useState();
  const [accrtitle, setAccrtitle] = useState();
  const [contentimage, setContentimage] = useState();
  const [contentdetails, setContentdetails] = useState();
  const [belowcontent, setBelowcontent] = useState();
  const [mvbgimage, setMvbgimage] = useState();
  const [mvdetails, setMvdetails] = useState();
  const [mvimage, setMvimage] = useState();
  const [distinguistitle, setDistinguistitle] = useState();
  const [distinguisdetails, setDistinguisdetails] = useState();
  const [ourcommunitytitle, setOurcommunitytitle] = useState();
  const [ourcommunitydetails, setOurcommunitydetails] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    const aboutdataquery = {
      method:'POST',
      url: 'https://mveximwp.mvexim.in/graphql',
      headers: {
        'content-type': 'application/json'
      },
      data: {
        query:`
        query AboutUsPagequery {
          page(id: "about-us", idType: URI) {
            bannerSection {
              bannerImage {
                altText
                title
                sourceUrl
              }
              bannerDescription
              bannerTitle
            }
            aboutUsPage {
              contentImage {
                altText
                uri
                sourceUrl
              }
              contentDetails
              belowContentDetails
              accredeiationsMainTitle
              missionVisionBackgroundImage {
                altText
                sourceUrl
                title
              }
              missionVisionImage {
                altText
                title
                sourceUrl
              }
              missionVisionDetails {
                title
                content
                image {
                  altText
                  sourceUrl
                  title
                }
              }
              distinguishesMainTitle
              distinguishesDetails {
                content
                image {
                  altText
                  title
                  sourceUrl
                }
                title
              }
              ourCommunityMainTitle
              ourCommunityDetails {
                content
              }
            }
          }
          accredeiations {
            nodes {
              featuredImage {
                node {
                  altText
                  sourceUrl
                  title
                }
              }
            }
          }
        }
                           
        `
      }
  }
  
  axios.request(aboutdataquery).then(function (response) {
  //console.log(response.data.data);
  setBannersection(response.data.data.page.bannerSection);
  setAccrimages(response.data.data.accredeiations.nodes);
  setAccrtitle(response.data.data.page.aboutUsPage.accredeiationsMainTitle)
  setContentdetails(response.data.data.page.aboutUsPage.contentDetails)
  setContentimage(response.data.data.page.aboutUsPage.contentImage)
  setBelowcontent(response.data.data.page.aboutUsPage.belowContentDetails)
  setMvbgimage(response.data.data.page.aboutUsPage.missionVisionBackgroundImage)
  setMvdetails(response.data.data.page.aboutUsPage.missionVisionDetails)
  setMvimage(response.data.data.page.aboutUsPage.missionVisionImage)
  setDistinguistitle(response.data.data.page.aboutUsPage.distinguishesMainTitle)
  setDistinguisdetails(response.data.data.page.aboutUsPage.distinguishesDetails)
  setOurcommunitytitle(response.data.data.page.aboutUsPage.ourCommunityMainTitle)
  setOurcommunitydetails(response.data.data.page.aboutUsPage.ourCommunityDetails)
  setLoading(false);
  }).catch(function (error) {
  console.error(error);
  })
  }, []);

 


  return (
    <>
    {loading ? (
        <Loader /> // Display the Loader component while loading
      ) : (
        // Render your data once loading is complete
        <div>
        {/* <Aboutsection1 bannerdata={bannersection}/> */}
        <Aboutsection4 mvbgimage={mvbgimage} mvdetails={mvdetails} mvimage={mvimage} />
        <Aboutsection2  contentdetails={contentdetails} contentimage={contentimage} belowcontent1={belowcontent} />
        <Aboutsction3 accrimage1={accrimages} accrtitle1={accrtitle}  />
        
        <Aboutsection5 distinguistitle={distinguistitle} distinguisdetails={distinguisdetails} />
        {/* <Aboutsection6 ourcommunitytitle={ourcommunitytitle} ourcommunitydetails1={ourcommunitydetails}  />         */}
          <Homesection7/>
        </div>
      )}
    
    </>
  )
}

export default About
