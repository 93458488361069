import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Loader from '../../Loader';

const Careersection2 = () => {
  const [loading, setLoading] = useState(true);
  const [formFields, setFormFields] = useState([]);
  const apiUrl = 'https://mveximwp.mvexim.in/multiverseapi/custom/v1/contact-form-fields/582';

  useEffect(() => {
    // Fetch form fields from the WordPress REST API
    axios.get(apiUrl).then((response) => {
      // Extract and set the form fields
      setFormFields(response.data);
      setLoading(false);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formValues = new FormData(e.target);
    const fileInput = document.getElementById('file-499');
    const allowedExtensions = /(\.pdf)$/i;
    const maxFileSizeMB = 2;

    // Validate required fields
    if (!formValues.get('FirstName') || !formValues.get('LastName') || !formValues.get('email') || !formValues.get('PhoneNumber')) {
      alert('Please fill in all required fields.');
      return;
    }

    // Validate file upload
    if (!fileInput.files || !fileInput.files[0]) {
      alert('Please upload a file.');
      return;
    }

    // Validate file type
    if (!allowedExtensions.exec(fileInput.value)) {
      alert('Please upload only PDF files.');
      return;
    }

    // Validate file size
    const fileSizeMB = fileInput.files[0].size / (1024 * 1024);
    if (fileSizeMB > maxFileSizeMB) {
      alert(`File size must be no more than ${maxFileSizeMB} MB.`);
      return;
    }

    // Continue with your logic when all conditions are met
    axios.post('https://mveximwp.mvexim.in/multiverseapi/contact-form-7/v1/contact-forms/582/feedback', formValues)
      .then((response) => {
        alert(response.data.message);
        document.getElementById('file-499').value = '';
        document.getElementById('FirstName').value = '';
        document.getElementById('LastName').value = '';
        document.getElementById('email').value = '';
        document.getElementById('PhoneNumber').value = '';
      })
      .catch((error) => {
        // Handle errors
      });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className='section-spacing bg-gray' id='writetous'>
          <div className='container'>
            <h2 className='section-title mb-3 text-uppercase'>Attach your Resume</h2>
            <p className='small'>Fields with (*) are required.</p>
            <form onSubmit={handleSubmit} className='kvtfont formfont row'>
              {formFields.map((field) => (
                <div className='col-md-6 col-12' key={field.label}>
                  <div className="mb-4">
                    <label htmlFor={field.id}>{field.label}</label>
                    <input type={field.type} id={field.name} name={field.name} className='form-control' />
                  </div>
                </div>
              ))}
              <div className='col-12'>
                <button type="submit" className="btn btn-yellow text-center">Send Now 
                  <svg className='buttonarrow' xmlns="http://www.w3.org/2000/svg" id="Component_1_8" data-name="Component 1 – 8" width="16" height="16" viewBox="0 0 16 16">
                    <path xmlns="http://www.w3.org/2000/svg" id="Path_33" data-name="Path 33" d="M14.689,6.913H2.95L8.387,1.475A.864.864,0,1,0,7.165.253L.253,7.166a.864.864,0,0,0,0,1.222L7.166,15.3a.864.864,0,1,0,1.222-1.222L2.95,8.641H14.689a.864.864,0,1,0,0-1.728Z" transform="translate(0 0)" fill="#000"/>
                  </svg>
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default Careersection2;