import React, { useState } from 'react'
import Loader from './Loader';
import axios from 'axios';
import Homesection7 from './components/homepage/Homesection7';
import Servicesection1 from './components/servicepage/Servicesection1';
import Servicesection2 from './components/servicepage/Servicesection2';
import '../src/assets/css/service.css'
import { ApolloProvider } from '@apollo/client';
import client from './Apollo';
import { useEffect } from 'react';

const Service = () => {

  const [loading, setLoading] = useState(true);
  const [bannerdata,setbannerdata] = useState();
  const [servicesdata, setServicesdata] = useState();
  const [servicesbgimage, setServicesbgimage] = useState();
  const [strategycontent, setStrategycontent] = useState();
  const [strategydetails, setStrategydetails] = useState();
  const [strategytitle, setStrategytitle] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    const servicequery = {
      method:'POST',
      url: 'https://mveximwp.mvexim.in/graphql',
      headers: {
        'content-type': 'application/json'
      },
      data: {
        query:`
        query ServicePagequery {
          page(id: "service", idType: URI) {
            bannerSection {
              bannerImage {
                altText
                title
                sourceUrl
              }
              bannerDescription
              bannerTitle
            }
            servicePage {
              serviceDetail
              serviceDetailBackgroundImage {
                altText
                title
                sourceUrl
              }
              strategyTitle
              strategyContent
              strategyDetails {
                title
                icon {
                  altText
                  title
                  sourceUrl
                }
              }
            }
          }
          services {
            nodes {
              title
              content(format: RENDERED)
              featuredImage {
                node {
                  altText
                  title
                  sourceUrl
                }
              }
            }
          }
        } 
        `
      }
  }
  
  axios.request(servicequery).then(function (response) {
    //console.log(response.data.data);
    setbannerdata(response.data.data.page.bannerSection) 
    setServicesbgimage(response.data.data.page.servicePage.serviceDetailBackgroundImage)
    setServicesdata(response.data.data.services.nodes)
    setStrategycontent(response.data.data.page.servicePage.strategyContent)
    setStrategytitle(response.data.data.page.servicePage.strategyTitle)
    setStrategydetails(response.data.data.page.servicePage.strategyDetails)

    setLoading(false);
    }).catch(function (error) {
    console.error(error);
    })
  }, []);

  

  return (
    <>
    {loading ? (
        <Loader /> // Display the Loader component while loading
      ) : (
        // Render your data once loading is complete
        <div>
        {/* <Servicesection1 bannerdata={bannerdata} /> */}
        <Servicesection2 servicedata1={servicesdata} sservicebbgimage={servicesbgimage} strategyContent={strategycontent} strategyDetails={strategydetails} strategyTitle={strategytitle} />        
        <Homesection7/>        
        </div>
      )}
    </>
  )
}

export default Service
