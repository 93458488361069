import React, { useState } from 'react'
import Loader from './Loader';
import axios from 'axios';
import Homesection7 from './components/homepage/Homesection7';
import Servicesection1 from './components/servicepage/Servicesection1';
import '../src/assets/css/service.css'
import { ApolloProvider } from '@apollo/client';
import client from './Apollo';
import { useEffect } from 'react';
import Careersection from './components/careerpage/Careersection';
import Careersection1 from './components/careerpage/Careersection1';
import Careersection2 from './components/careerpage/Careersection2';

const Career = () => {
  window.scrollTo(0, 0);
  const [loading, setLoading] = useState(true);
  const [bannerdata,setbannerdata] = useState();
  const [gallerydata,setGallerydata] = useState();

  useEffect(() => {
    const servicequery = {
      method:'POST',
      url: 'https://mveximwp.mvexim.in/graphql',
      headers: {
        'content-type': 'application/json'
      },
      data: {
        query:`
        query CareerPageQuery {
          page(id: "careers", idType: URI) {
            bannerSection {
              bannerImage {
                altText
                title
                sourceUrl
              }
              bannerDescription
              bannerTitle
            }
            careerPage {
              lifeAtMultiverseTitle
              galleryDetails {
                galleryImage {
                  altText
                  sourceUrl
                  title
                }
              }
            }
          }
        }
             
        `
      }
  }
  
  axios.request(servicequery).then(function (response) {
    //console.log(response.data.data);
    setbannerdata(response.data.data.page.bannerSection) 
    setGallerydata(response.data.data.page.careerPage);
    setLoading(false);
    }).catch(function (error) {
    console.error(error);
    })
  }, []);

  

  return (
    <>
    {loading ? (
        <Loader /> // Display the Loader component while loading
      ) : (
        // Render your data once loading is complete
        <div>
        <Careersection1 bannerdata={bannerdata} />
        <Careersection gallerydata={gallerydata} />
        <Careersection2/>        
        </div>
      )}
    </>
  )
}

export default Career
